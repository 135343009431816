<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Tiers for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceTiers" />
      </v-card-title>

      <v-card-text>      
        Customize the tiers(levels) for this leaderboard.
      </v-card-text>

      <v-card-text>      
        <strong>Tip:</strong> the tier with the highest value will show on top of the leaderboard. You can leave either the min or max value empty, but not both.
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="tiers"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Tiers</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-text-field v-model="item.name" hide-details />
        </template>
        <template v-slot:item.value="{ item }">
          <DistanceTextArea v-model="item.value" :unit="event.unit" hideDetails :mode="$helpers.UnitType.NUMBER" />
        </template>
        <template v-slot:item.min_score="{ item }">
          <DistanceTextArea v-model="item.min_score" :unit="event.unit" hideDetails :mode="$helpers.UnitType.NUMBER" />
        </template>
        <template v-slot:item.max_score="{ item }">
          <DistanceTextArea v-model="item.max_score" :unit="event.unit" hideDetails :mode="$helpers.UnitType.NUMBER" />
        </template>
        <template v-slot:footer>
          <v-btn text @click="addRow"><v-icon small color="gray" class="mr-2">fa-plus-circle</v-icon> Add a tier</v-btn>
          <span class="text-muted">(To delete a tier: clear the display name and press Save)</span>
        </template>
      </v-data-table>

      <v-card-text>
        <v-btn color="primary" large @click="save">Save</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "RaceTiers",
  components: {
    Header,
    RaceSelector,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      tiers: [],
      selectedRaceIds: [],
      selectRacesDialog: false,
      showDialog: false,
      siteData: siteData,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Value', value: 'value', sortable: false },
        { text: 'Min Score (inclusive)', value: 'min_score', sortable: false },
        { text: 'Max Score (exclusive)', value: 'max_score', sortable: false},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.tiers = this.race.tiers || [];
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async addRow() {
      this.tiers.push({});
    },

    async save() {
      console.log('Saving', this.tiers);
      const response = (await eventManagerService.putRaceTiers(this.event.id, this.race.id, this.tiers)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved tiers.');

      await this.getProfile();
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Tiers', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
.handle {cursor: move;}
</style>

